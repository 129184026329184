import React from "react"
import Imgix from "react-imgix"
import styles from "./stylesheets/logisticsSection.module.scss"
import cx from "classnames"

export default function LogisticsSection({ logisticsData }) {
  const title =
    logisticsData.logistics_title[0] && logisticsData.logistics_title[0].text
  const desktopImage = logisticsData.desktop_logistics_image
  const mobileImage = logisticsData.mobile_logistics_image

  return (
    <section className={cx(styles.logisticsContainer, "mt-5 text-center")}>
      <h3 className={cx(styles.logisticsTitle, "px-5 py-5 pb-md-0 mb-0")}>
        {title}
      </h3>
      <div className="d-none d-md-block">
        <Imgix
          src={desktopImage.url}
          alt={desktopImage.alt}
          className={cx(styles.desktopGraphic, "w-100")}
          sizes="100vw"
        />
      </div>
      <div className="d-block d-md-none">
        <Imgix
          src={mobileImage.url}
          alt={mobileImage.alt}
          className="w-100"
          sizes="100vw"
        />
      </div>
    </section>
  )
}

import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroSectionWithImage from "../components/heroSectionWithImage"
import FullWidthImage from "../components/fullWidthImage"
import LogisticsSection from "../components/logisticsSection"
import TwoColumnBlurbSection from "../components/twoColumnBlurbsSection"
import { standardizePrismicType } from "../utils/standardizePrismicType"

export const query = graphql`
  query AboutUsQuery {
    prismic {
      allAbout_uss {
        edges {
          node {
            about_meta_title
            about_meta_description
            body {
              ... on PRISMIC_About_usBodyHero_section {
                primary {
                  hero_description
                  hero_image
                  page_tagline
                  page_title
                }
              }
              ... on PRISMIC_About_usBodyFull_width_image {
                primary {
                  image
                }
              }
              ... on PRISMIC_About_usBodyLogistics_solutions {
                primary {
                  mobile_logistics_image
                  logistics_title
                  desktop_logistics_image
                }
              }
              ... on PRISMIC_About_usBodyTwo_column_w__icons {
                fields {
                  service_column
                  service_icon
                  service_item
                }
                primary {
                  call_to_action_button_text
                  subaction_text
                  subaction_destination_url {
                    _linkType
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const About = ({ data }) => {
  const page = data.prismic.allAbout_uss.edges[0]
  if (!page) return null
  const prismicSections = page.node.body.map((section) =>
    standardizePrismicType(section),
  )
  if (!prismicSections) return null

  const metaTitle =
    page.node.about_meta_title && page.node.about_meta_title[0]?.text
  const metaDescription =
    page.node.about_meta_description &&
    page.node.about_meta_description[0]?.text

  return (
    <Layout activePage="about" bottomBorder={true}>
      <SEO metaTitle={metaTitle} metaDescription={metaDescription} />
      <div>
        {prismicSections.map((prismicSection) => {
          let template
          switch (prismicSection.__typename) {
            case "PRISMIC_About_usBodyFull_width_image":
              template = (
                <FullWidthImage
                  src={prismicSection.primary.image.url}
                  alt={prismicSection.primary.image.alt}
                />
              )
              break
            case "PRISMIC_About_usBodyHero_section":
              template = (
                <HeroSectionWithImage heroData={prismicSection.primary} />
              )
              break
            case "PRISMIC_About_usBodyLogistics_solutions":
              template = (
                <LogisticsSection logisticsData={prismicSection.primary} />
              )
              break
            case "PRISMIC_About_usBodyTwo_column_w__icons":
              template = (
                <TwoColumnBlurbSection
                  blurbsData={prismicSection.fields}
                  callToActionData={prismicSection.primary}
                />
              )
              break
            default:
              template = null
          }
          return template
        })}
      </div>
    </Layout>
  )
}

export default About

import React from "react"
import Imgix from "react-imgix"
import HeroSection from "./heroSection"
import ContactModal from "./contactModal"
import { Button } from "react-bootstrap"
import styles from "./stylesheets/heroSectionWithImage.module.scss"
import cx from "classnames"

export default function HeroSectionWithImage({ heroData, hasJobListingsLink }) {
  const [showContactModal, setShowContactModal] = React.useState(false)
  const title = heroData.page_tagline && heroData.page_tagline[0]?.text
  const description = heroData.hero_description
  const pageType = heroData.page_title && heroData.page_title[0]?.text
  const imageData = heroData.hero_image
  const callToActionText =
    heroData.call_to_action_button_text &&
    heroData.call_to_action_button_text[0]?.text
  const leadSource =
    heroData.salesforce_lead_source && heroData.salesforce_lead_source[0]?.text
  const subActionText =
    heroData.subaction_text && heroData.subaction_text[0]?.text
  const subActionUrl = heroData.subaction_destination_url?.url

  return (
    <div className={styles.topLevelContainer}>
      <section className="row no-gutters flex-md-row-reverse px-lg-5 mb-3">
        <div className="col-12 col-lg-6 px-0 justify-self-center align-self-start pb-2 pt-md-3 mt-md-4">
          <Imgix
            src={imageData.url}
            alt={imageData.alt}
            className={styles.heroImage}
            width={750}
          />
        </div>
        <div className="col-12 col-lg-6 pl-md-5 pl-lg-0 pr-md-5 my-md-4 mb-4 mb-md-0">
          <HeroSection
            title={title}
            description={description}
            pageType={pageType}
            hasJobListingsLink={hasJobListingsLink}
          />
          {callToActionText && (
            <div className="d-flex align-items-center justify-content-center justify-content-md-start">
              <Button
                onClick={() => setShowContactModal(true)}
                className={cx(
                  styles.sectionCtaButton,
                  "btn text-white px-5 py-3 mb-3",
                )}
              >
                {callToActionText}
              </Button>
              <ContactModal
                show={showContactModal}
                setShow={setShowContactModal}
                hideAdvanced={heroData.contact_form_hide_advanced_fields}
                hideNumberOfVehicles={
                  heroData.contact_form_hide_number_of_vehicles
                }
                hideCompany={heroData.contact_form_hide_company}
                leadSource={leadSource}
              />
            </div>
          )}
          {subActionText && (
            <div className={cx(styles.subactionContainer, "text-center")}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={subActionUrl}
                className={styles.subactionLink}
              >
                {subActionText}
              </a>
            </div>
          )}
        </div>
      </section>
    </div>
  )
}

import React from "react"
import { RichText } from "prismic-reactjs"
import { linkResolver } from "../utils/linkResolver"
import styles from "./stylesheets/heroSection.module.scss"
import cx from "classnames"

export default function HeroSection({
  title,
  description,
  pageType,
  hasJobListingsLink,
  noPadding,
}) {
  const handleJobListingsClick = () => {
    document
      .getElementById("jobListings")
      .scrollIntoView({ behavior: "smooth" })
  }

  return (
    <div className={cx(!noPadding && "pr-md-4", "px-3 px-md-0")}>
      <div className={cx(styles.pageType, "pb-3 font-weight-bold")}>
        {pageType}
      </div>
      <div
        className={cx(
          styles.pageTitle,
          !noPadding && "pr-md-5",
          "pb-3 font-weight-bold",
        )}
      >
        {title}
      </div>
      <div className={cx(styles.heroCopy, "pb-3")}>
        <RichText render={description} linkResolver={linkResolver} />
      </div>
      {hasJobListingsLink && (
        <div>
          <button
            onClick={() => handleJobListingsClick()}
            className={cx(
              styles.internalLink,
              "bg-transparent border-0 text-primary px-0",
            )}
          >
            View Job Openings >
          </button>
        </div>
      )}
    </div>
  )
}
